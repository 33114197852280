<template>
    <div style="background-color: white;width: calc(100vw - 215px);height: calc(100vh - 50px);overflow-y: auto;">
        <div style="padding: 20px;box-sizing: border-box;margin: 0 auto;width: 80%;max-width: 1340px;">
            <div style="display: flex;align-items: center;justify-content: flex-start;margin-top: 30px;">
                <img src="../../assets/image/arrow_left.png" style="width: 24px;height: 24px;cursor: pointer;"
                    @click="goBack">
                <p style="color: #333;font-size: 18px;font-weight: bold;">
                    Select the period for EAE Universal Pass subscription
                </p>
            </div>
            <div style="margin-top: 30px;display: flex;">
                <div class="procut-item">
                    <div class="item-top-cover">
                        <img src="../../assets/image/product_item_bg1.png" class="cover-img">
                        <p class="price">{{ getAmount(1) }}<span
                                style="font-size:20px;font-weight: normal;margin-left: 5px;">SGD</span>
                        </p>
                    </div>
                    <div class="item-content">
                        <p class="year">1 Year</p>
                        <p class="from-to" style="margin-top: 15px;">From: {{ startDate }}</p>
                        <p class="from-to" style="margin-top: 5px;">To: {{ oneYearTo }}</p>
                        <p class="suitable">Suitable for grades 11&12</p>
                        <p class="btn-select" @click="gotopay(1)">Select</p>
                    </div>
                </div>

                <div class="procut-item" style="margin: 0px 30px;">
                    <div class="item-top-cover">
                        <img src="../../assets/image/product_item_bg2.png" class="cover-img">
                        <p class="price">{{ getAmount(2) }}<span
                                style="font-size:20px;font-weight: normal;margin-left: 5px;">SGD</span>
                        </p>
                        <div class="save">
                            <p class="save-price">Save <br> {{ getDiscount(2) }} SGD</p>
                        </div>
                    </div>
                    <div class="item-content">
                        <p class="year">2 Years</p>
                        <p class="from-to" style="margin-top: 15px;">From: {{ startDate }}</p>
                        <p class="from-to" style="margin-top: 5px;">To: {{ towYearTo }}</p>
                        <p class="suitable">Suitable for grades 10&11</p>
                        <p class="btn-select" @click="gotopay(2)">Select</p>
                    </div>
                </div>

                <div class="procut-item">
                    <div class="item-top-cover">
                        <img src="../../assets/image/product_item_bg3.png" class="cover-img">
                        <p class="price">{{ getAmount(3) }}<span
                                style="font-size:20px;font-weight: normal;margin-left: 5px;">SGD</span>
                        </p>
                        <div class="save save2">
                            <p class="save-price">Save <br> {{ getDiscount(3) }} SGD</p>
                        </div>
                    </div>
                    <div class="item-content">
                        <p class="year">3 Years</p>
                        <p class="from-to" style="margin-top: 15px;">From: {{ startDate }}</p>
                        <p class="from-to" style="margin-top: 5px;">To: {{ threeYearTo }}</p>
                        <p class="suitable">Suitable for grades 9&10</p>
                        <p class="btn-select" @click="gotopay(3)">Select</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import '../../assets/common/font.css'
import {
    getUniversalPassStatus
} from '../../api/eae'
import {
    getUserId
} from '../../utils/store'
export default ({
    name: 'index',
    data() {
        return {

            fees: [
                {
                    year: 1,
                    amount: 980,
                    discount: 0,
                },
                {
                    year: 2,
                    amount: 1660,
                    discount: 300,
                },
                {
                    year: 3,
                    amount: 2200,
                    discount: 740,
                }
            ],

            currentDayTime: 0,

            startDate: '',
            oneYearTo: "",
            towYearTo: '',
            threeYearTo: ''
        }
    },
    mounted() {


        this.fetchData()
    },
    methods: {


        getAmount(year) {
            for (let i = 0; i < this.fees.length; i++) {
                let item = this.fees[i]
                if (item.year == year) {
                    return item.amount
                }
            }
            return 0
        },
        getDiscount(year) {
            for (let i = 0; i < this.fees.length; i++) {
                let item = this.fees[i]
                if (item.year == year) {
                    return item.discount
                }
            }
            return 0
        },


        fetchData() {
            //获取学生是否购买了up
            getUniversalPassStatus(getUserId()).then((res) => {
                let result = res.data
                let currentDay = null
                if (result.code == 0) {
                    let expire_time = result.data.expire_time
                    currentDay = new Date(expire_time)
                } else {
                    currentDay = new Date()
                }
                let one_day = 24 * 60 * 60 * 1000
                currentDay.setHours(0, 0, 0, 0)
                this.startDate = this.formatTimeToEng(currentDay.getTime())
                currentDay.setFullYear(currentDay.getFullYear() + 1)
                this.oneYearTo = this.formatTimeToEng(currentDay.getTime() - one_day)
                currentDay.setFullYear(currentDay.getFullYear() + 1)
                this.towYearTo = this.formatTimeToEng(currentDay.getTime() - one_day)
                currentDay.setFullYear(currentDay.getFullYear() + 1)
                this.threeYearTo = this.formatTimeToEng(currentDay.getTime() - one_day)
            })
        },

        formatTimeToEng(time) {
            var date = new Date(time);
            let year = date.getFullYear();
            let day = date.getDate();
            let month = date.getMonth() + 1;
            return this.formatMonthToEng(month) + " " + day + ", " + year;
        },

        goBack() {
            this.$router.go(-1)
        },
        gotopay(year) {
            this.$router.push({
                path: '/home/up-order',
                query: {
                    year: year
                }
            })
        },
        formatMonthToEng(month) {
            switch (month) {
                case 1:
                    return "January";
                case 2:
                    return "February";
                case 3:
                    return "March";
                case 4:
                    return "April";
                case 5:
                    return "May";
                case 6:
                    return "June";
                case 7:
                    return "July";
                case 8:
                    return "August";
                case 9:
                    return "September";
                case 10:
                    return "October";
                case 11:
                    return "November";
                case 12:
                    return "December";
            }
        },
    }
})
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
    display: none;
}

.procut-item {
    width: 320px;
    height: 485px;
    border-radius: 12px;
}

.item-top-cover {
    width: 100%;
    height: 173px;
    position: relative;

    .save {
        top: -16px;
        position: absolute;
        right: 15px;
        background-image: url('../../assets/image/product_arrow1.png');
        background-position: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        width: 120px;
        height: 108px;
        text-align: center;

        .save-price {
            margin-left: 10px;
            margin-top: 25px;
            font-size: 20px;
            line-height: 24px;
            color: white;
        }
    }

    .save2 {
        background-image: url('../../assets/image/product_arrow2.png');
    }

    .cover-img {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .price {
        color: white;
        font-size: 32px;
        font-weight: bold;
        position: absolute;
        left: 30px;
        bottom: 30px;
    }
}

.item-content {
    padding: 20px 30px 30px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #DEDEDE;

    .year {
        font-size: 26px;
        color: #333;
        font-weight: bold;
    }

    .from-to {
        color: #333;
        font-size: 16px;
    }

    .suitable {
        margin-top: 30px;
        font-size: 18px;
        color: #333;
        font-weight: bold;
    }

    .btn-select {
        cursor: pointer;
        margin-top: 40px;
        border-radius: 5px;
        width: 100%;
        height: 42px;
        text-align: center;
        line-height: 42px;
        color: white;
        font-size: 18px;
        font-weight: bold;
        background-color: var(--c-primary);
    }

}
</style>